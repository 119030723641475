import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter, first, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SideMenuItems } from './shared/modules/side-menu/classes/SideMenuItems';
import { SideMenuOptions } from './shared/modules/side-menu/classes/SideMenuOptions';
import { routerTransition } from '../animations';
import { AuthService } from './shared/modules/auth/auth.service';
import { BaseUser } from './classes/user/BaseUser';
import { BaseComponent } from './shared/modules/base-component/base.component';
import { SideMenuItem } from './shared/modules/side-menu/classes/SideMenuItem';
import { AppConfig } from './config/app.config';
import { MasterDataService } from './shared/services/master-data.service';
import { UserRole } from './classes/user/UserRole';

/** Main component */
@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition],
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  public sideMenuOptions = new SideMenuOptions();
  public slideContent: boolean;
  public slideHeader: boolean;
  public sideMenuItems: SideMenuItems[];

  loggedInUser: BaseUser;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private authService: AuthService,
    private masterDataService: MasterDataService
  ) {
    super();
  }

  ngOnInit() {
    this.initTranslate();
    this.initLoggedInUser();
    this.listenForNavigationEndChanges();
    this.initMasterData();

    return super.ngOnInit();
  }

  handleMenuToggle({ header, content }): void {
    this.slideHeader = header;
    this.slideContent = content;
  }

  headerHasStickySideBar(): boolean {
    return this.sideMenuOptions.hasStickySideBar && !this.sideMenuOptions.isFixedBelowHeader;
  }

  prepareRouterTransition(outlet: RouterOutlet): void {
    return outlet.activatedRouteData.animation;
  }

  private initLoggedInUser(): void {
    this.authService.loggedInUserChange.pipe(untilDestroyed(this)).subscribe((user) => {
      this.loggedInUser = user;

      this.initSideMenuItems();
    });
  }

  private initTranslate(): void {
    this.translate.addLangs(AppConfig.LANGUAGES);
    this.translate.setDefaultLang(AppConfig.DEFAULT_LANGUAGE);
    this.translate.use(AppConfig.DEFAULT_LANGUAGE);
  }

  private initSideMenuItems(): void {
    const items = [
      new SideMenuItem(
        'PatientRegister.PageTitle',
        true,
        'menu/users.svg',
        'register-patient-user'
      ),
    ];

    if (this.loggedInUser?.role === UserRole.WebAdmin) {
      items.push(new SideMenuItem('Article.PageTitle', true, 'menu/articles.svg', 'articles'));
    }

    this.sideMenuItems = [{ groupTitle: '', items }];
  }

  private listenForNavigationEndChanges(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.route.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot) {
              return child.snapshot;
            } else {
              return null;
            }
          }

          return null;
        }),
        untilDestroyed(this)
      )
      .subscribe((snapshot: any) => {
        this.setTitle(snapshot);
      });
  }

  private initMasterData() {
    this.masterDataService.initAll();
  }

  private setTitle(snapshot): void {
    if (snapshot.data && snapshot.data.title) {
      this.translate
        .get(snapshot.data.title)
        .pipe(first())
        .subscribe((translated) => {
          this.titleService.setTitle(translated);
        });
    }
  }

  /* eslint-disable @typescript-eslint/member-ordering */
  ngOnDestroy() {
    return super.ngOnDestroy();
  }
}
